import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How Our Portfolio Wizard Works`}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` See also `}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/find-your-portfolio/"
          }}>{`Using the portfolio wizard`}</a></li>
      </ul>
    </Aside>
    <p>{`The portfolio wizard is a useful and convenient tool for quickly finding a portfolio that matches your needs. However, we still encourage investors to learn and understand how our tool works and how we match your investment objectives with the portfolios, as well as have an understanding of what you are choosing to invest in. `}</p>
    <h2>{`Time Horizon and Volatility`}</h2>
    <p>{`The investor’s time horizon is one of the biggest factors in choosing the proper portfolio. A thirty year old saving for retirement, for example, will have a much different time horizon, and thus should choose a much different portfolio, than a parent of a teenager saving up for their child’s college education. `}</p>
    <p>{`Due to market volatility, investors cannot expect their account value to increase smoothly. Instead, prices fluctuate up and down on a shorter term basis. While in the long term we will see capital growth, volatility will cause peaks and troughs in the short term.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0cd49039a6723c1561af3af9ba6ac1fd/21b4d/spx-25yr-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACJ0lEQVQoz2VTWW7bMBTU/U/RC/Sz6FfRpkGROkkTx2kU21piLVZsbZZILaRkzRRy5aDLAAPOI4mH4cPQ2O1eEQQB4jiGEBLDMPB4PGLkqEcAOHHEuNf1PZpWUciG0T7nPimQ5oJCVjC22xBFUfy+TWLiucAZY6l1h6bVbFTP9FBzn0nKWjMXGpmo2TQ1jDAMkOc5JieY3Lw1PUN3PZu2p6haxknCoswpqwNFumUWvSBa31EcMhhRtEWWZX81HHE8gkofWTejg4ZZ2XKfpAwDl0Xisc4CVq82U2/BePOAnW+yksX45D8bDqe17weWomCaJUzTmLvtCx3rJ333mZFncu89014/cGHec+5YvLNXWLgr5mUOIwx8JEn8NsOyUrS9LS3boueaNJ9mfHq6pb+Z8zUyaXsmlxuHK8/l0nMYRTbzdI0gtFhXEkaaJpCyhlId3Y2Hb7Mrzu9vePfjmje3lzSf53Rci0+Wz8fVmp6/pOs7jMIV6yJgI2K2VQkowb5TMNJDSXO55Oz6Al8uP2F29RnzxXesnUe4vgV7YyMMLcjchswclGmIXoaAluAwnFNxGlbbKhjfry/48cM7zi7fc7P6SpE8UB6WrMuIukrYNSm75kBdZey05hTN/zI25lMpBUM1NcpDDiFSHLVErwR6VaFrSyjVoOvG7Cm0Sp+01hpaKWitplWf2LbteE7jHNrJ9onTx8Aw5UipFn3XnfQwDG9h/1ePTn8BjNJC9NEQviIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "spx 25yr cumulative returns",
            "title": "spx 25yr cumulative returns",
            "src": "/static/0cd49039a6723c1561af3af9ba6ac1fd/c1b63/spx-25yr-cumulative-returns.png",
            "srcSet": ["/static/0cd49039a6723c1561af3af9ba6ac1fd/5a46d/spx-25yr-cumulative-returns.png 300w", "/static/0cd49039a6723c1561af3af9ba6ac1fd/0a47e/spx-25yr-cumulative-returns.png 600w", "/static/0cd49039a6723c1561af3af9ba6ac1fd/c1b63/spx-25yr-cumulative-returns.png 1200w", "/static/0cd49039a6723c1561af3af9ba6ac1fd/21b4d/spx-25yr-cumulative-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can describe investments through two statistical properties of their returns: the compound annual growth rate (CAGR), and standard deviation of the CAGR, also called volatility. `}</p>
    <p>{`With these properties at hand, we can use `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Monte_Carlo_method"
      }}>{`Monte Carlo simulations`}</a>{` to model the probable outcomes of our investments. The chart above shows a hypothetical investment in the S&P 500. As we can see, there is a broad range of possible outcomes, represented by a swarm of squiggly lines. We can transform these into a relatively smooth envelope by looking at the outcomes at the 5th and 95th percentile. While these values are far from the best case or worst case outcomes, there is a 90% chance that the actual returns will fall somewhere between these two extremes. Consequently, these boundaries represent a reasonable range for the expected investment result. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e90b5f556dffb65710d706d0f0dec949/b59fb/spx-10yr-cumulative-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACI0lEQVQoz2WS+W7TQBCH/f5PgsQDwB9ICCFaFCiFJm3iJs5lN77vYw/b+yGbqlAx0mhXOzvfzvx2LCEERVGQZRlN09D3PUpriqoljGJc78Tu0WZ/2HO0V+SxT1FX5ElAXmR0oiXNU+I0ZOgVVpJEeJ5HXddM8MmEBj/MKIqKIL7gOBuWj1uOjk0eujxFMUkcEGQxWVlTpmfCYEtVVVhB4BNHMf+aG4ScjjvOwYW7zZKVs8ZxPU7nDd7JwTvsyKuEtM7JixIGSVYk+JGPFYUhYRC+wBqh+b5asb77im0veDg4/NisidxH/DBgd7gjTFwaVaJ0ixk6jIFhNDSdmFqOX1XonE8sFtes7SVP9++5Xy5wnVvK1CWrUoRuaWRLKWq0VnRdPed1skeIbqowwPf9F+D1zTc+fHzDp6+fse9vOdo3VMdb4qdfqPYEY42ZABq6JqGuCkYlEIWHbDOsKAoI/OAF+LC64urLWx6W79icjqTHn5RZSFsHGNNjmhW0W3TtorqKYYCuTRlkhNYaS0rJMJ1OQkwvtwWH9YIu2xLFOy7unrLcI2SO0Q2mcTDVGiN8zCgwOkLJknEcmFjWNHeTGWNmn0wKSd9k5KFDXXgYmYLKMeKC6WswI4wdDNOnNFM242jmsbOmMv8D6oGhNxR1ilTVsxhTfJj1m11nfyR4jo7j+BeolHrlQkraTtBJgVSStmnny0rpua15FS1q3j/nCDFL9xuXG0T6MSYKqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "spx 10yr cumulative returns",
            "title": "spx 10yr cumulative returns",
            "src": "/static/e90b5f556dffb65710d706d0f0dec949/c1b63/spx-10yr-cumulative-returns.png",
            "srcSet": ["/static/e90b5f556dffb65710d706d0f0dec949/5a46d/spx-10yr-cumulative-returns.png 300w", "/static/e90b5f556dffb65710d706d0f0dec949/0a47e/spx-10yr-cumulative-returns.png 600w", "/static/e90b5f556dffb65710d706d0f0dec949/c1b63/spx-10yr-cumulative-returns.png 1200w", "/static/e90b5f556dffb65710d706d0f0dec949/b59fb/spx-10yr-cumulative-returns.png 1285w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When looking at the S&P 500 over 25 years, our investment appears to be growing at a solid rate, even though the best case far outperforms the assumed worst case. But after zooming into the 10 year range, we see that at the 5th percentile this investment might well be under water for the first eight years.	`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4d8c892395b7bb0c04e77d351fe36717/21b4d/60-40-vs-spx-cagr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABsklEQVQoz03QXWvTcBTH8f+ETQRvfAHe+CLEd6AX4tOUDdH6AHX1AUQvvBB24YXoXcEhsyCCwkQRwQthiAynoigItjPJRqWmXda1Zm2apmn6T5rkK7Mp24EPB87FOYefWFBtsu9Mvv52gJDNinyXhlmnVquir5UpGTq6sYpRrVIqVzCbFl4Q4PYkXeknJGEYIk4+1BHjecYyKkdmKswuNvlc7FJ3ImR/eGJY0bbeTx4Y9gDXdRGpxxXEOYXRjIY4qyBSCjsuqey+uszeW0X23/3DwWyFyZzB1LN1bryoMf3G5P58g+z7JrMfLHIfW7z60WajYSULUwo7L2uMZQZG0iriooo4ryDO/EJMLiFOFRDjBcTxAuJofsuxPOLwT0anNNZNCzHxqIyYWNpacEFhJK2x68oye66vsO92kQP3Shx6oHMip3P6SZn03CrXXhrcfL3G9Nsad+b/MrNo0nZcxKcVm6dfWjz/3mLu28CC1qFYl2x0fGwp8aKA6H9WUSJOQBy6IC2IPYLARxAHSajb9dmcx2GA7HnYloXjOEg/oCd9vN5A1/OxnS6tdoeO6xFFEf8Aoe/IVcF62zUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "60 40 vs spx cagr",
            "title": "60 40 vs spx cagr",
            "src": "/static/4d8c892395b7bb0c04e77d351fe36717/c1b63/60-40-vs-spx-cagr.png",
            "srcSet": ["/static/4d8c892395b7bb0c04e77d351fe36717/5a46d/60-40-vs-spx-cagr.png 300w", "/static/4d8c892395b7bb0c04e77d351fe36717/0a47e/60-40-vs-spx-cagr.png 600w", "/static/4d8c892395b7bb0c04e77d351fe36717/c1b63/60-40-vs-spx-cagr.png 1200w", "/static/4d8c892395b7bb0c04e77d351fe36717/21b4d/60-40-vs-spx-cagr.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The best way to express portfolio performance is through the CAGR, and we can calculate these returns at the 5th and 95th percentile from our Monte Carlo simulation of possible outcomes. The result is, again, forming a cone of possible investment outcomes. However, this time the cone is rather wide at the beginning, and continually narrows with a longer investment horizon. This tells us that over sufficiently long investment periods, the volatility evens out and leads to more consistent results. In the short term though, volatility has a large effect on the average annual returns. It is also worth noting that the best case and worst case outcomes do not converge completely, at least not within a lifetime.`}</p>
    <p>{`These Monte Carlo simulations allow us to look at our investments from a different angle, providing us with some valuable insight. The chart above compares a sample 60/40 stock to bond portfolio with an investment in the S&P 500. We know that the balanced portfolio has a lower average return than an investment completely in the stock market.`}</p>
    <p>{` But the chart also shows us that under worst case conditions, it takes twenty-five years for the S&P 500 to catch up with a typical 60/40 portfolio . The chart also tells us that even a relatively safe 60/40 portfolio is too risky for a five year investment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2809c9710d7c49d751dca460ff0c0dec/21b4d/all-stars-tr-vs-all-stars-lev-cagr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeklEQVQoz5XRXUsUYRjG8efQb9BnEDzuGwSdBJ0IngR75oEEQSBERAgGJoIUIUK0KIjUgmCIiBFkSKgoSWAQhDkv66o788yuO7OzM8/L/kUnQ8STbvhxnVzccHOLZpLTUQWjDdZa2kmC67r4vo/v+biOi+e6HFYPcQ4cwjC86Cmt0X8ppTDGIJa+R4AlsxmJzohVh/S81OUGXQxgLGht0aZgzlNb4jhGlMoeH7ZbFNO9kvYKc429QZcokoj+KQfxYI++5/vce+1TKtd49P6Ip4vHjC7XGV8NmPwc8uaLZPqr5O16RPmbZGZDMrspmdsOmN8JqOyGVI9DxN3JP4j+H/QM7XFr+Ce9I7+4/fI3d17tc3/6gIF3DqVZl8F5j4cVn8cLVZ58rPFsqcbI8hEvVuqMrUomPkn8kyaisiWZWgvwGm2MvTznfyaHVEKngVI5AnSxxGq0Uqhckec5WVZoxQkn9YAwapBmOe00+ydJM06TlGYroXkaX3z5DMit+3JQdxTLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars tr vs all stars lev cagr",
            "title": "all stars tr vs all stars lev cagr",
            "src": "/static/2809c9710d7c49d751dca460ff0c0dec/c1b63/all-stars-tr-vs-all-stars-lev-cagr.png",
            "srcSet": ["/static/2809c9710d7c49d751dca460ff0c0dec/5a46d/all-stars-tr-vs-all-stars-lev-cagr.png 300w", "/static/2809c9710d7c49d751dca460ff0c0dec/0a47e/all-stars-tr-vs-all-stars-lev-cagr.png 600w", "/static/2809c9710d7c49d751dca460ff0c0dec/c1b63/all-stars-tr-vs-all-stars-lev-cagr.png 1200w", "/static/2809c9710d7c49d751dca460ff0c0dec/21b4d/all-stars-tr-vs-all-stars-lev-cagr.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows another example, this time comparing the `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` portfolio with `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-lev/"
      }}>{`All-Stars Leveraged`}</a>{`. You can see that, over the first three years, `}<em parentName="p">{`All-Stars Total Return`}</em>{` is likely to have higher returns than its leveraged cousin. However, after that period, `}<em parentName="p">{`All-Stars Leveraged`}</em>{` will likely lead to higher returns, albeit with much broader variability.`}</p>
    <p>{`The important takeawey is, that investment risk is not just a personal preference. Instead, we can find the optimal risk level for an investment based on the investment period. Consequently, `}<em parentName="p">{`TuringTrader`}</em>{`'s portfolio wizard calculates the expected returns at the 5th and 95th percentiles, and compares them at the end of the investment horizon. The wizard disqualifies all those portfolios yielding negative returns, and ranks the remaining portfolios by their worst case return. This method makes sure that you don't pick a portfolio that is too risky for the investment period.`}</p>
    <h2>{`Risk Tolerance and Maximum Drawdown`}</h2>
    <p>{`By comparing the worst case returns at the end of the planned investment horizon we can be reasonably sure we are making a sound investment decision. However, not everything about investing can be measured simply in rational terms. The investor's psyche plays an important role, as well. `}</p>
    <p>{`Investing goes hand in hand with experiencing loss and can be stressful at times, even when doing everything correctly. This is where the investor's personal risk tolerance comes in. If an investment causes sleepless nights, or if you are tempted to second guess or even abandon the strategy, it is too risky for you.`}</p>
    <p>{`A mistake many investors make is that they overestimate their appetite for risk. They start investing aggressively, and then try to cut the losses in their portfolio after the market experiences a decline. `}</p>
    <p>{`This is actually a worst case scenario on two different fronts. First, the investor experiences the deeper drawdowns from the more aggressive and more risky strategy. Then, after selling, it will take longer for their account to recover because the less risky portfolio has lower long term returns. The overall investment result is most likely much worse than investing in the less risky portfolio right from the start.	`}</p>
    <p>{`Investors typically measure their risk tolerance through the maximum percentage they are prepared to lose from a previous high. Unfortunately, the maximum loss is not tied to a specific probability, and we never know how far in the tail an observed correction actually was. Therefore, the number can fluctuate to a large degree based on very small variations of the strategy, for example, trading one day too late.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8dc558cb8815dd933812938d982da9a2/21b4d/60-40-vs-spx-dd.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABDUlEQVQY04WQuUpDQRSGBxt7BStfxafQTiSVYCEEGytfIG9gZW1p5wNYC2JihDQukCKGuVnuzSxn7swnc2NECegHP//hzHIWdXA5YPPkiZ2zZ7bbfbbafTaOe+yeD3h4t2SMRGZi8TE0crVgxGGDxwS/dHGUZYk6vX5F7T+ijrqowy6q1WOv88L9m2GFjR6fhP/QukANZyUXNx+0roZ0bsfcDRbfF+qYms8WtYMEKaU1rcjxpChQLuSx4lo1X9dU0eJT+LMr6wMikutRaI0yxjKzC7SZU5hy6XbO3FU47wgizQPv/bpEGBcTRqMR0+kU5xyqafvnGDERvzznrbVoramqqjmPMf5S3sUqn/kEUxbAH+UZQz0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "60 40 vs spx dd",
            "title": "60 40 vs spx dd",
            "src": "/static/8dc558cb8815dd933812938d982da9a2/c1b63/60-40-vs-spx-dd.png",
            "srcSet": ["/static/8dc558cb8815dd933812938d982da9a2/5a46d/60-40-vs-spx-dd.png 300w", "/static/8dc558cb8815dd933812938d982da9a2/0a47e/60-40-vs-spx-dd.png 600w", "/static/8dc558cb8815dd933812938d982da9a2/c1b63/60-40-vs-spx-dd.png 1200w", "/static/8dc558cb8815dd933812938d982da9a2/21b4d/60-40-vs-spx-dd.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To remedy this shortcoming, we again use the Monte Carlo simulation to calculate a range of probable outcomes. First, we rank all drawdowns, regardless how small or short-lived, and pick the deepest drawdowns at the 5th percentile. Next, we calculate an envelope of the underwater percentage at the 5th percentile. There is no guarantee that the resulting number is the worst possible drawdown, however, the number will provide investors with a good estimate of what they realistically need to expect losing during a major economic crisis, such as a recession.`}</p>
    <p>{`The chart above, compares the 60/40 portfolio against the S&P 500. The drawdown estimate for the 60/40 is about 25%, while the estimate for the S&P 500 lands at roughly 45%. In comparison, the 2008 recession resulted in a drawdown of 36% for the 60/40, and 55% for the S&P 500, showing a slight mismatch in the probability of the 2008 recession and our statistical assumptions. However, these discrepancies are not indicative of a flawed method, it simply means that the 2008 recession was extreme enough to land about two standard deviations away from the mean.`}</p>
    <p>{`Curious investors will find that for many portfolios our number calculated from the Monte Carlo simulation actually is more pessimistic than the backtested result. Our portfolio wizard uses the drawdown figure from the Monte Carlo simulation because it is more stable and better suited for comparisons between portfolios than the maximum drawdown.`}</p>
    <h2>{`Account Taxation`}</h2>
    <p>{`When tactical asset allocation rotates the assets in your investment account, unrealized profits and losses turn into realized ones. Unless the investment account is tax-deferred, such as a 401(k) or IRA account, the investor will owe taxes on the realized gains. Therefore, taxation may play an important role in picking a suitable portfolio.`}</p>
    <p>{`There are basically two approaches to dealing with taxes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tax efficient investing`}</strong>{`: By using hedging techniques, we can combine the benefits of tactical asset allocation, namely better risk-adjusted returns, with the tax-efficiency of buy and hold investments. To learn more, we encourage curious investors to read our article on `}<a parentName="li" {...{
          "href": "/articles/tax-efficient-investing/"
        }}>{`tax-efficient-investing`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Outsized return`}</strong>{`: If a tactical portfolio provides outsized returns, this may be enough to justify the additional tax burden. For this to happen, the tactical portfolio will need to be quite aggressive, and the investment horizon needs to reflect the additional volatility.`}</li>
    </ul>
    <p>{`For taxable accounts, our portfolio wizard aims to accurately rank the portfolios by their expected after-tax returns. To do so, we assume a certain percentage of each portfolio to result in short-term capital gains. This percentage is highly dependent on the portfolio's trading style and listed on the respective portfolio pages. The wizard then assumes that the short-term capital gains (and interest payments) are taxed at 37%, while the assumed long-term capital gains are taxed at 15%. And while your exact tax rates might be different, this estimate should nonetheless provide good guidance.`}</p>
    <p>{`You will notice though, that the tax status leads to only subtle ranking changes at the top of the table. However, this somewhat surprising result makes sense: most tax-efficient portfolios also come with higher volatility than their conventional cousins. As a result, the estimated returns at the 5th percentile are lower for the tax-efficient portfolios, which leads to a lower rankings. This supports our opinion that investors should prioritize low volatility over tax efficiency when selecting their portfolios.`}</p>
    <h3>{`Account Size`}</h3>
    <p>{`Tactical portfolios may differ in the type of assets they invest in, the number of assets they hold at any time, and in the accuracy they require for proper position sizing. Let's look at two examples:`}</p>
    <ul>
      <li parentName="ul">{`Our simplest portfolios hold one or two ETFs at any time, with capital allocated equally between them. If we assume that most ETFs trade around $100, it is probably feasible to invest as little as $2,000 in such portfolios.`}</li>
      <li parentName="ul">{`More complex portfolios hold five or more high-flying individual stocks, and require position sizing accurate to 10%. To meet the position sizing accuracy, we would need to hold at least 10 shares. With stocks often trading at $500 or more, this may result in a minimum investment of $25,000.`}</li>
    </ul>
    <p>{`These examples should make it obvious that account size is an important criterion in choosing a portfolio. Even though many brokerages offer `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/f/fractionalshare.asp"
      }}>{`fractional shares`}</a>{`, it is our opinion that the account size should be adequate to implement the portfolio without using this feature.`}</p>
    <p>{`TuringTrader's portfolio wizard uses a simple tag to find portfolios that work with the desired account size. This number should be taken with a grain of salt, as it is only an estimate, and if your account is only a few dollars short that's probably not an issue.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`TuringTrader's portfolio wizard is an extremely valuable tool to help investors find portfolios that match their investment goals. With just a few clicks, the wizard captures the most important investment criteria, and displays portfolios suitable for the investor's application. Unlike `}</p>
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      